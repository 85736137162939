// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
export type Account = {
  homePage: string;
  name: string;
};

export type ActionableBulkLoadForm = {
  actionableIds: bigint[];
  corporateId: number | null;
  includeInactive: boolean;
};

export type ActionableId = {
  contentType: "ACTIONABLE";
  id: bigint;
};

export type ActionableListPortletView = {
  items: ActionableMetadata[];
  metadata: PortletMetadata;
  page: number;
  pageSize: number;
  totalCount: bigint;
  viewAllUri: string | null;
};

export type ActionableMetadata = {
  actionableId: bigint;
  activatedAt: DateTime<true> | null;
  active: boolean;
  coverUri: string;
  description: string;
  language: Language;
  modifiedAt: DateTime<true>;
  name: string;
  tags: Tag[];
  title: string;
};

export type ActionableMiniView = {
  actionableId: bigint;
  coverUri: string;
  name: string;
  title: string;
};

export type ActionablePageProps = {
  actionableId: bigint;
  freeActionableActive: boolean;
  isMapp: boolean;
};

export type Activity = {
  definition: ActivityDefinition;
  id: string;
  objectType: string;
};

export type ActivityDefinition = {
  description: { additionalProperties: Record<string, string> };
  moreInfo: string;
  name: { additionalProperties: Record<string, string> };
  type: string;
};

export type Actor = {
  account: Account | null;
  mbox: string | null;
  name: string;
  objectType: string;
  openid: string | null;
};

export type AdminSidebarWidgetProps = {
  navigationItems: AdminToolLinkGroup[];
};

export type AdminToolLink = {
  description: string | null;
  hidden: boolean;
  ico: string | null;
  linkText: string | null;
  tabName: string | null;
  tabTitle: string | null;
  targetType: AdminToolLinkTargetType;
  url: string | null;
};

export type AdminToolLinkGroup = {
  ico: string | null;
  links: AdminToolLink[];
  name: string | null;
};

export namespace AdminToolLinkTargetType {
  const values = ["SELF", "BLANK", "ROUTER"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AdminToolLinkTargetType = AdminToolLinkTargetType.Impl;

export type AlipayPaymentView = {
  outTradeNo: string | null;
  tradeNo: string | null;
  transactionId: number;
};

export type AndroidPaymentView = {
  appStoreProductId: string | null;
  autoRenewing: boolean;
  countryCode: string | null;
  orderId: string | null;
  paymentState: number;
  purchaseState: number;
  testPurchase: boolean;
  transactionId: number;
};

export namespace AskGetabstractAnalyticsEventVariant {
  const values = ["default", "qa_triggered_from_search", "qa_demo"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AskGetabstractAnalyticsEventVariant = AskGetabstractAnalyticsEventVariant.Impl;

export type AskGetabstractAnalyticsFeedbackEventForm = {
  isPositive: boolean;
  questionUuid: string;
};

export type AskGetabstractAnswer = {
  answer: string;
  contextDocs: AskGetabstractAnswerContextDoc[];
  detectedLanguageLabel: string | null;
  previousQuestionUuid: string | null;
  question: string;
  questionUuid: string;
  relatedActionables: ActionableMiniView[];
  relatedQuestions: string[];
  status: AskGetabstractAnswerStatus;
};

export type AskGetabstractAnswerContextDoc = {
  bookmarked: boolean;
  dataId: number;
  rating: number | null;
  referenceNumber: number;
  snippet: string;
  sourceTypeString: string;
  title: string;
  urls: {
    cover: string;
    list: string;
    reference: string;
  };
};

export namespace AskGetabstractAnswerStatus {
  const values = ["PROCESSING", "FINISHED", "NO_DOCS_FROM_VECTOR_DB", "NO_DOCS_AFTER_FILTER", "NO_REFERENCE_IN_ANSWER", "GENERAL_ERROR", "TIMEOUT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AskGetabstractAnswerStatus = AskGetabstractAnswerStatus.Impl;

export type AskGetabstractProperties = {
  isTrendingQuestion: boolean;
  language: Language;
  question: string | null;
  questionUuid: string | null;
  showAiDataProtectionDisclaimer: boolean;
};

export type AskGetabstractQuestion = {
  analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
  blacklistQuestions: string[];
  previousQuestionUuid: string | null;
  question: string;
  questionUuid: string;
  relatedQuestionStep: number;
  trendingQuestion: boolean;
};

export type AskGetabstractQuestionAnswers = {
  current: AskGetabstractAnswer;
  history: AskGetabstractAnswer[];
};

export type AuthorAdminListFilters = {
  authorId: string | null;
  channels: string | null;
  firstName: string | null;
  gender: string | null;
  lastName: string | null;
  middleName: string | null;
};

export type AuthorAdminListView = {
  authorId: bigint;
  channels: string[];
  firstName: string;
  gender: Gender;
  lastName: string | null;
  middleName: string | null;
};

export type AuthorEditView = {
  authorId: bigint | null;
  firstName: string;
  gender: Gender;
  ignoringWarning: boolean;
  lastName: string | null;
  middleName: string | null;
};

export type B2BStatsReportPageProps = {
  b2bReportForm: NewB2BReportForm;
  corporateId: number;
  hasExtendedAccess: boolean;
  reportTypes: DropdownEntry[];
};

export type B2BStatsReportSaveResponse = { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; reportId: number };

export type B2CRenewalEmails = {
  name: string;
  sendDate: DateTime<true>;
  type: string;
};

export type B2CSubscription = {
  actionStatus: string | null;
  customerId: number;
  editSubscriptionUrl: string;
  endDate: { year: number; month: number; day: number };
  giftSubscription: GiftSubscription | null;
  isCredited: boolean;
  isExpired: boolean;
  isLoss: boolean;
  isMobileSubscription: boolean;
  isPaid: boolean;
  isTeamSubscription: boolean;
  manualRenewalUrl: string;
  numberOfTransactions: number;
  product: string;
  productId: number;
  quantity: number;
  randomKey: string;
  startDate: { year: number; month: number; day: number };
  studentVerification: StudentVerificationView | null;
  subscriptionId: number;
  subscriptionPayment: SubscriptionPayment;
  subscriptionStatus: SubscriptionStatus;
  teamSubscription: TeamSubscription | null;
};

export type CareerLocalizationForm = {
  benefits: string;
  content: string;
  location: string;
  title: string;
};

export type ChannelAdminSearchForm = {
  corporateId: number | null;
  includeInactive: boolean | null;
  includeTemporary: boolean | null;
  query: string;
};

export type ChannelAdminTagBasedProps = {
  channelId: bigint;
  managerSelection: ChannelManager[];
};

export type ChannelCoverEditForm = { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string };

export type ChannelEditForm = {
  active: boolean;
  channelId: bigint;
  channelLocalization: { additionalProperties: Record<string, ChannelLocalizationEditForm> };
  childChannels: ChannelRelationForm[];
  cover: ChannelCoverEditForm;
  definition: string | null;
  name: string;
  notes: string | null;
  primarySortOrder: number | null;
  tag: Tag;
};

export type ChannelEditView = {
  active: boolean;
  channelId: bigint;
  channelLocalization: { additionalProperties: Record<string, ChannelLocalizationView> };
  childChannels: ChannelRelationView[];
  cover: string | null;
  definition: string | null;
  name: string;
  notes: string | null;
  primarySortOrder: number | null;
  tag: Tag;
};

export type ChannelLocalizationEditForm = {
  active: boolean;
  description: string | null;
  manager: number | null;
  synonym: string | null;
  title: string | null;
};

export type ChannelLocalizationView = {
  active: boolean;
  description: string | null;
  manager: number | null;
  synonym: string | null;
  title: string | null;
};

export type ChannelManager = {
  id: number;
  name: string;
};

export type ChannelMetadata = {
  activationTime: DateTime<true> | null;
  active: boolean;
  channelId: bigint;
  channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
  coverUri: string | null;
  definition: string | null;
  descriptionHtml: string | null;
  modificationTime: DateTime<true> | null;
  name: string;
  synonyms: string | null;
  tag: Tag;
  title: string | null;
  visibleLanguages: Language[];
};

export type ChannelRelationForm = {
  channelId: bigint;
  sortOrder: number | null;
  title: string;
};

export type ChannelRelationView = {
  channelId: bigint;
  sortOrder: number | null;
  title: string;
};

export type ChannelSaveResponse = { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; channelId: bigint };

export type ChannelTextLocalization = {
  description: string | null;
  synonym: string | null;
  title: string | null;
};

export type ConnectorCompletionExecutionLogListView = {
  completedAt: DateTime<true> | null;
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
  userId: string;
};

export type ConnectorCompletionFilters = {
  timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS";
  userId: string | null;
};

export type ConnectorContentExecutionListView = {
  connectorExecutionId: bigint;
  connectorName: string;
  description: string;
  detail: ConnectorContentExecutionLogDetailView;
  provider: ConnectorProviderMetadata;
  syncedAt: DateTime<true> | null;
  type: "WEB" | "BATCH";
};

export type ConnectorContentExecutionLogDetailView = {
  failedExportedItems: number;
  successfullyExportedItems: number;
};

export type ConnectorContentExecutionLogListView = {
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
};

export type ConnectorExecutionLogMetadata = {
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
};

export namespace ConnectorExecutionType {
  const values = ["WEB", "BATCH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorExecutionType = ConnectorExecutionType.Impl;

export type ConnectorExecutionView = {
  connectorExecutionId: bigint;
  executedAt: DateTime<true>;
  executionType: ConnectorExecutionType;
  logsCount: number;
  provider: ConnectorProviderMetadata;
};

export type ConnectorForm = {
  active: boolean;
  languages: Language[];
  name: string;
  products: ConnectorProduct[];
  settingsForm: ConnectorSettingsForm;
  syncCompletions: boolean;
  type: ConnectorType;
};

export namespace ConnectorGrantType {
  const values = ["CLIENT_CREDENTIALS", "PASSWORD"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorGrantType = ConnectorGrantType.Impl;

export type ConnectorListView = {
  active: boolean;
  connectorId: bigint;
  name: string;
  provider: ConnectorProviderMetadata;
  syncedAt: DateTime<true> | null;
};

export namespace ConnectorProduct {
  const values = ["BUSINESS_BOOKS", "ARTICLES_AND_REPORTS", "VIDEOS_AND_PODCASTS", "ACTIONABLES"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorProduct = ConnectorProduct.Impl;

export type ConnectorProviderMetadata = {
  imageUri: string;
  name: string;
  type: ConnectorType;
};

export type ConnectorRealTimeCompletionInfo = {
  completedAt: DateTime<true>;
  contentId: string;
  corporateId: number;
  customerId: number;
  duration: bigint;
  progress: number;
};

export type ConnectorSettingsForm = {
  accountId: string | null;
  apiKey: string | null;
  clientId: string;
  clientSecret: string;
  doceboCatalogCode: string | null;
  doceboChannelId: bigint | null;
  grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
  lmsApiUri: string | null;
  lmsBaseUri: string;
  lmsTokenUri: string | null;
  oauth2Flow: Oauth2Flow;
  password: string | null;
  scope: string | null;
  sfCompanyId: string | null;
  userId: string | null;
  userIdentifier: ConnectorUserIdentifier;
  username: string | null;
  workdayCategoryFallback: string | null;
  xapiActorId: XApiActorId;
};

export type ConnectorSettingsView = {
  accountId: string | null;
  apiKey: string | null;
  clientId: string;
  clientSecret: string;
  doceboCatalogCode: string | null;
  doceboChannelId: bigint | null;
  grantType: ConnectorGrantType;
  lmsApiUri: string | null;
  lmsBaseUri: string;
  lmsTokenUri: string | null;
  oauth2Flow: Oauth2Flow;
  password: string | null;
  scope: string | null;
  sfCompanyId: string | null;
  userId: string | null;
  userIdentifier: ConnectorUserIdentifier;
  username: string | null;
  workdayCategoryFallback: string | null;
  xapiActorId: XApiActorId;
};

export type ConnectorSyncEntry = {
  connectorId: bigint;
  corporateId: number;
  type: ConnectorType;
};

export type ConnectorSyncForm = {
  actionableIds: bigint[];
  dataIds: number[];
};

export namespace ConnectorType {
  const values = ["SUCCESS_FACTORS", "DEGREED", "DOCEBO", "DOCEBO_CATALOG", "CSOD", "SUM_TOTAL", "EDCAST", "WORKDAY", "VIVA_LEARNING", "ORACLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorType = ConnectorType.Impl;

export namespace ConnectorUserIdentifier {
  const values = ["NAME_ID", "EMAIL", "EXTRA_FIELD_1", "EXTRA_FIELD_2", "EXTRA_FIELD_3"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorUserIdentifier = ConnectorUserIdentifier.Impl;

export type ConnectorView = {
  connectorId: bigint;
  languages: Language[];
  name: string;
  products: ConnectorProduct[];
  settings: ConnectorSettingsView;
  syncCompletions: boolean;
  type: ConnectorType;
};

export type ContentCardDemoProps = {
  actionables: ContentMetadata[];
  miabs: ContentMetadata[];
  sketchnotes: ContentMetadata[];
  summaries: ContentMetadata[];
};

export type ContentId = { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint };

export namespace ContentItemType {
  const values = ["UNKNOWN", "SUMMARY", "CATEGORY", "CHANNEL", "LGXP", "ACTIONABLE", "MYLIST", "CUSTOMPAGE", "MIAB"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ContentItemType = ContentItemType.Impl;

export namespace ContentLibrary {
  const values = ["BUSINESS_BOOKS", "CLASSICS_BOOKS", "ARTICLES_AND_REPORTS", "VIDEOS_AND_PODCASTS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ContentLibrary = ContentLibrary.Impl;

export type ContentMetadata =
  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; sourceType: SummarySourceType }
  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
  | { contentType: "MIAB"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; sourceType: SummarySourceType };

export type ContentMetadataActionable = {
  common: ContentMetadataCommon;
  contentId: ActionableId;
  contentType: "ACTIONABLE";
};

export type ContentMetadataCommon = {
  audioActive: boolean;
  coverUri: string;
  description: string | null;
  inactive: boolean;
  language: Language;
  leadIn: string | null;
  link: string | null;
  title: string;
};

export type ContentMetadataLgxp = {
  common: ContentMetadataCommon;
  contentId: LgxpId;
  contentType: "SKETCHNOTE";
};

export type ContentMetadataMiab = {
  backgroundColor: string | null;
  common: ContentMetadataCommon;
  contentId: SummaryId;
  contentType: "MIAB";
  documentType: SummaryDocumentType;
  sourceType: SummarySourceType;
};

export type ContentMetadataSummary = {
  backgroundColor: string | null;
  common: ContentMetadataCommon;
  contentId: SummaryId;
  contentType: "SUMMARY";
  documentType: SummaryDocumentType;
  sourceType: SummarySourceType;
};

export type CorporateLicences = {
  consumedLicences: number;
  limitedLicences: boolean;
  numberLicences: number;
  unidentifiedUsers: number;
};

export type CorporationLecacyForm = {
  info: string;
};

export type CorporationPortalAdminView = {
  active: boolean;
  corporateId: number;
  corporation: string;
  lcResponsible: CustomerView | null;
  logoUrl: string | null;
  portalUrl: string;
  signupUrl: string | null;
  username: string;
};

export type Country = {
  countryCode: string;
  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
  names: {
    de: string;
    en: string;
    es: string;
    fr: string;
    pt: string;
    ru: string;
    zh: string;
  };
  phonePrefix: string;
  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
};

export type CreditCardPaymentView = {
  acqAuthorizationCode: string | null;
  authorizationCode: string | null;
  batchRefNumber: string | null;
  creditCardAlias: string | null;
  creditCardNumber: string;
  creditCardType: CreditCardType;
  datatransMerchantId: string | null;
  expirationMonth: number;
  expirationYear: number;
  gatewayProvider: string;
  nameOnCard: string | null;
  refNumber: string | null;
  transactionDateTime: DateTime<true>;
  transactionId: number;
};

export namespace CreditCardType {
  const values = ["VISA", "MASTERCARD", "AMEXCO"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type CreditCardType = CreditCardType.Impl;

export type CustomPageAdminSearchForm = {
  includeInactive: boolean;
  onlyHighlighted: boolean;
  searchTerm: string;
};

export type CustomPageCopyRequest = {
  toCorporateId: number;
};

export type CustomPageEditForm = {
  active: boolean;
  customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
  defaultCover: boolean;
  headerLayout: CustomPageHeaderLayout;
  portletIds: number[];
};

export type CustomPageEditListView = {
  active: boolean;
  corporateId: number;
  corporation: string;
  customPageId: bigint;
  highlightPriority: number | null;
  modifiedAt: DateTime<true>;
  titles: { additionalProperties: Record<string, string> };
};

export type CustomPageEditListViewArray = {
  arr: CustomPageEditListView[];
};

export type CustomPageEditView = {
  active: boolean;
  corporateId: number;
  coverUrl: string;
  customPageId: bigint;
  customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
  headerLayout: CustomPageHeaderLayout;
  portlets: PortletMetadata[];
};

export type CustomPageFilters = {
  includeInactive: boolean;
  onlyHighlighted: boolean;
};

export namespace CustomPageHeaderLayout {
  const values = ["NONE", "DEFAULT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type CustomPageHeaderLayout = CustomPageHeaderLayout.Impl;

export type CustomPageHighlightsForm = {
  customPageIds: bigint[];
};

export type CustomPageListView = {
  corporateId: number;
  coverUri: string;
  customPageId: bigint;
  description: string | null;
  title: string;
};

export type CustomPageLocalizationEditForm = {
  description: string | null;
  title: string;
};

export type CustomPageLocalizationEditView = {
  description: string | null;
  title: string;
};

export type CustomPageView = {
  coverUrl: string;
  customPageId: bigint;
  description: string | null;
  headerLayout: CustomPageHeaderLayout;
  portlets: PortletMetadata[];
  title: string;
};

export type CustomerAdminB2BPortalsForm = {
  corporateId: number;
  corporation: string;
  corporationIsActive: boolean;
  customerIsActive: boolean;
  endDate: { year: number; month: number; day: number } | null;
  isExpired: boolean;
  responsibleCsm: string | null;
  responsibleSales: string | null;
  startDate: { year: number; month: number; day: number } | null;
  type: string | null;
};

export type CustomerAdminDetailWidgetProps = {
  customerId: number;
};

export type CustomerAdminFreeAbstractCoupon = {
  affiliateCorporateId: number;
  affiliateCorporation: string;
  affiliateUserName: string;
  couponBalance: number;
  couponRegistationBalance: number;
  registrationDate: { year: number; month: number; day: number };
  summaryGroupId: number;
};

export type CustomerAdminMobileAppForm = {
  loginId: number;
  loginPreferences: MobileAppLoginPreferenceListView[];
  mobileDevices: MobileDevice[];
  oAuth2Tokens: OAuth2Token[];
  showLoginPreferences: boolean;
};

export type CustomerAdminSubscriptionsForm = {
  customerId: number;
  customerLanguageId: number;
  customerName: string;
  customerRandomCode: string;
  emailHistory: B2CRenewalEmails[];
  hasStudentSubscription: boolean;
  studentPreverification: StudentVerificationView | null;
  subscriptions: B2CSubscription[];
};

export type CustomerView = {
  customerId: number;
  email: string;
  fullName: string;
};

export type DateRange = {
  from: { year: number; month: number; day: number };
  to: { year: number; month: number; day: number };
};

export type DisplayMonetaryAmount = {
  amount: number;
  currencyCode: string;
  displayValue: string;
};

export namespace DownloadType {
  const values = ["PUSH", "PULL", "BOTH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type DownloadType = DownloadType.Impl;

export type DropdownEntry = {
  text: string;
  value: string | null;
};

export type EmailCategoryFilters = {
  categoryId: number | null;
  categoryName: string | null;
  followUpType: EmailCategoryFollowUpType;
};

export namespace EmailCategoryFollowUpType {
  const values = ["STANDARD", "INTERVAL", "DATEBASED"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryFollowUpType = EmailCategoryFollowUpType.Impl;

export type EmailCategoryForm = {
  description: string | null;
  details: EmailCategoryFormDetails;
  name: string;
  repositoryId: number;
  sendCountriesOnly: string[];
  sendMatchingLanguageOnly: boolean;
};

export type EmailCategoryFormDetails =
  | { repositoryType: "DEFAULT" }
  | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
  | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
  | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
  | {
      repositoryType: "FOLLOWUP";
      active: boolean;
      corporateIds: number[];
      emailingPools: string[];
      followupType: EmailCategoryFollowUpType;
      interval: number;
      maxDaysAfterExpiration: number;
      minDaysAfterExpiration: number;
      sendToAll: boolean;
      startDate: { year: number; month: number; day: number } | null;
    }
  | { repositoryType: "MANUAL"; corporateIds: number[] };

export type EmailCategoryManualSendForm = {
  executionDate: { year: number; month: number; day: number } | null;
  notifyEmail: string;
};

export namespace EmailCategoryTypeDefault {
  const values = ["DEFAULT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeDefault = EmailCategoryTypeDefault.Impl;

export namespace EmailCategoryTypeEducation {
  const values = ["EDUCATION"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeEducation = EmailCategoryTypeEducation.Impl;

export namespace EmailCategoryTypeFollowUp {
  const values = ["FOLLOWUP"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeFollowUp = EmailCategoryTypeFollowUp.Impl;

export namespace EmailCategoryTypeManual {
  const values = ["MANUAL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeManual = EmailCategoryTypeManual.Impl;

export namespace EmailCategoryTypeOnboarding {
  const values = ["ONBOARDING"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeOnboarding = EmailCategoryTypeOnboarding.Impl;

export namespace EmailCategoryTypeRenewal {
  const values = ["RENEWAL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailCategoryTypeRenewal = EmailCategoryTypeRenewal.Impl;

export type EmailDefaultCategoryAdminView = {
  categoryId: number;
  name: string;
  repositoryId: number;
};

export type EmailEducationCategoryAdminView = {
  active: boolean;
  categoryId: number;
  name: string;
  productIds: number[];
  repositoryId: number;
};

export type EmailFollowUpCategoryAdminView = {
  active: boolean;
  categoryId: number;
  corporateIds: string[];
  interval: number;
  maxDaysAfterExpiration: number;
  minDaysAfterExpiration: number;
  name: string;
  repositoryId: number;
  startDate: { year: number; month: number; day: number } | null;
};

export type EmailManualCategoryAdminView = {
  categoryId: number;
  corporateIds: string[];
  name: string;
  repositoryId: number;
};

export type EmailOnboardingCategoryAdminView = {
  active: boolean;
  categoryId: number;
  maxDaysAfterSignup: number;
  minDaysAfterSignup: number;
  name: string;
  repositoryId: number;
};

export type EmailPoolAdminView = {
  corporateIds: number[];
  name: string;
};

export type EmailPoolForm = {
  corporateIds: number[];
};

export type EmailRenewalCategoryAdminView = {
  active: boolean;
  categoryId: number;
  maxDaysAfterExpiration: number;
  minDaysAfterExpiration: number;
  name: string;
  productIds: number[];
  repositoryId: number;
};

export type EmailRepositoryAdminView = {
  comment: string | null;
  name: string;
  repositoryId: number;
  type: EmailRepositoryType;
};

export namespace EmailRepositoryType {
  const values = ["DEFAULT", "FOLLOWUP", "RENEWAL", "EDUCATION", "ONBOARDING", "MANUAL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailRepositoryType = EmailRepositoryType.Impl;

export type EmailTemplateCategoryInfo = {
  alternativeRecipientDefault: string;
  categoryName: string;
  defaultSender: string;
  footerOptions: DropdownEntry[];
  repositoryId: number;
  repositoryName: string;
  runningBatchProcess: boolean;
  templates: EmailTemplateEditView[];
};

export type EmailTemplateEditView = {
  analyticsCampaign: string | null;
  attachmentPath: string | null;
  contentHtml: string | null;
  contentPlain: string | null;
  contentSidebar: string | null;
  emailFrameId: number | null;
  emailTitle: string | null;
  footerSnippet: string | null;
  language: Language;
  linkTemplateCategoryId: number | null;
  linkTemplateId: number | null;
  sender: string | null;
  subject: string;
  templateId: number;
  templateLanguage: "SNIPPETS" | "VELOCITY";
};

export type EmailTemplateForm = {
  analyticsCampaign: string | null;
  attachmentPath: string | null;
  contentHtml: string | null;
  contentPlain: string | null;
  contentSidebar: string | null;
  emailFrameId: number | null;
  emailTitle: string | null;
  footerSnippet: string | null;
  language: Language;
  linkTemplateId: number | null;
  sender: string | null;
  subject: string;
  templateLanguage: "SNIPPETS" | "VELOCITY";
};

export type EmailTemplateTestForm = {
  alternativeRecipient: string | null;
  corporateId: number | null;
  customerId: number;
  dataIds: number[];
  format: EmailTemplateTestFormat;
  method: EmailTemplateTestMethod;
  subscriptionId: number | null;
};

export namespace EmailTemplateTestFormat {
  const values = ["HTML", "PLAINTEXT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailTemplateTestFormat = EmailTemplateTestFormat.Impl;

export namespace EmailTemplateTestMethod {
  const values = ["BROWSER", "EMAIL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type EmailTemplateTestMethod = EmailTemplateTestMethod.Impl;

export type EmailTemplateTestResult = {
  format: EmailTemplateTestFormat;
  method: EmailTemplateTestMethod;
  resultBody: string | null;
  success: boolean;
};

export type ExportReportListView = {
  downloadLink: string;
  exportDate: { year: number; month: number; day: number };
  exportTaskId: number;
  fileName: string;
  hash: string;
  reportName: string | null;
  reportType: ReportType | null;
};

export namespace FeatureType {
  const values = ["ACTIONABLE", "GET_ABSTRACT_AI"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FeatureType = FeatureType.Impl;

export type FilteredLearningItemAdminView = {
  items: LearningItemAdminView[];
  totalCount: number;
};

export type FormFilter = {
  activeValues: string[];
  identifier: FormFilterName;
  label: string;
  name: string;
  options: FormFilterOption[];
  priority: FormFilterPriority;
  type: FormFilterType;
};

export namespace FormFilterName {
  const values = ["CHANNEL", "SOURCE", "QUALITY", "RATING", "AUDIO", "LANGUAGE", "PUBLICATION_DATE", "MIAB"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FormFilterName = FormFilterName.Impl;

export type FormFilterOption = {
  label: string;
  shortLabel: string | null;
  value: string;
};

export namespace FormFilterPriority {
  const values = ["PRIMARY", "SECONDARY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FormFilterPriority = FormFilterPriority.Impl;

export namespace FormFilterType {
  const values = ["MULTICHECKBOX", "CHECKBOX", "MULTISELECT", "RANGE", "RANGE_MIN"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FormFilterType = FormFilterType.Impl;

export namespace FrontendProject {
  const values = ["VUE", "DESIGN"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FrontendProject = FrontendProject.Impl;

export type FullSearchProps = {
  beta: boolean;
  hasCorporatePlus: boolean;
  initialActionableFormFilters: FormFilter[];
  initialSummaryFormFilters: FormFilter[];
  query: string | null;
};

export type FullSearchResults = {
  actionableFormFilters: FormFilter[];
  actionables: ActionableMetadata[];
  channelPaging: {
    page: number;
    totalCount: bigint;
  };
  channels: ChannelMetadata[];
  customPages: CustomPageListView[];
  downloadSourceParams: { additionalProperties: Record<string, string> };
  summaries: SummaryMetadata[];
  summariesPaging: {
    page: number;
    totalCount: bigint;
  };
  summaryFormFilters: FormFilter[];
};

export type GaContextDataSync = {
  currentLanguage: Language;
  flags: GaContextFlags;
};

export type GaContextFlags = {
  beta: boolean;
};

export namespace Gender {
  const values = ["FEMALE", "MALE", "UNKNOWN", "CUSTOM"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Gender = Gender.Impl;

export namespace GiftCodeStatus {
  const values = ["AVAILABLE", "UNAVAILABLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type GiftCodeStatus = GiftCodeStatus.Impl;

export type GiftDonorSubscription = {
  donorCustomerId: number;
  donorSubscriptionId: number;
};

export type GiftReceiverSubscription = {
  receiverCustomerId: number;
  receiverSubscriptionId: number;
};

export type GiftSubscription = {
  donorSubscription: GiftDonorSubscription | null;
  giftReceiverSubscription: GiftReceiverSubscription | null;
};

export type Invoice = {
  generatedAt: DateTime<true>;
  invoiceId: number;
  invoiceLevel: number;
  invoiceUri: string | null;
  pdfCounter: number;
  transactionId: number;
};

export type InvoicePaymentView = {
  paidAt: DateTime<true> | null;
  reference: string | null;
  transactionId: number;
};

export type IosPaymentView = {
  appStoreProductId: string;
  autoRenewProductId: string | null;
  autoRenewing: boolean;
  expirationIntent: number;
  itunesTransactionId: string;
  originalItunesTransactionId: string | null;
  testPurchase: boolean;
  transactionId: number;
  webOrderLineItemId: bigint;
};

export namespace ItemLayout {
  const values = ["MINI", "REGULAR"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ItemLayout = ItemLayout.Impl;

export namespace KindChannel {
  const values = ["channel"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindChannel = KindChannel.Impl;

export namespace KindClassification {
  const values = ["classification"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindClassification = KindClassification.Impl;

export namespace KindDynamicBoolean {
  const values = ["dynamicBoolean"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicBoolean = KindDynamicBoolean.Impl;

export namespace KindDynamicNumber {
  const values = ["dynamicNumber"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicNumber = KindDynamicNumber.Impl;

export namespace KindDynamicString {
  const values = ["dynamicString"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicString = KindDynamicString.Impl;

export namespace KindDynamicVoid {
  const values = ["dynamicVoid"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicVoid = KindDynamicVoid.Impl;

export namespace KindKeyQuality {
  const values = ["key-quality"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindKeyQuality = KindKeyQuality.Impl;

export namespace KindPrimarySkill {
  const values = ["primary-skill"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindPrimarySkill = KindPrimarySkill.Impl;

export namespace KindSkill {
  const values = ["skill"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindSkill = KindSkill.Impl;

export namespace Language {
  const values = ["en", "de", "es", "ru", "zh", "pt", "fr"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Language = Language.Impl;

export type LearningCalendarView = {
  bannerImgUri: string;
  doors: any[];
  endDate: { year: number; month: number; day: number } | null;
  expiresAt: { year: number; month: number; day: number } | null;
  learningCalendarId: bigint;
  name: string;
  startDate: { year: number; month: number; day: number };
};

export type LearningItemAdminActionableView = {
  description: string;
  learningItemType: LearningItemTypeActionable;
  metadata: LearningItemAdminMetadataView;
};

export type LearningItemAdminFilter = {
  activatedAtRange: DateRange | null;
  active: boolean | null;
  audio: boolean | null;
  createdAtRange: DateRange | null;
  documentTypes: SummaryDocumentType[];
  ids: number[];
  itemTypes: LearningItemType[];
  languages: Language[];
  manager: string | null;
  missingTagKinds: TagKind[];
  publisher: string | null;
  resultSize: number;
  sourceTypes: SummarySourceType[];
  tagCount: number | null;
  tagKinds: TagKind[];
  tags: Tag[];
  title: string | null;
};

export type LearningItemAdminMetadataView = {
  activatedAt: { year: number; month: number; day: number } | null;
  active: boolean;
  createdAt: { year: number; month: number; day: number };
  deactivatedAt: { year: number; month: number; day: number } | null;
  id: bigint;
  language: Language;
  manager: string | null;
  tags: Tag[];
  title: string;
};

export type LearningItemAdminProps = {
  filter: LearningItemAdminFilter;
  views: ("READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW")[];
};

export type LearningItemAdminSummaryView = {
  audioActive: boolean;
  documentType: SummaryDocumentType;
  learningItemType: LearningItemTypeSummary;
  metadata: LearningItemAdminMetadataView;
  publisher: string | null;
  sourceType: SummarySourceType;
};

export type LearningItemAdminView =
  | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
  | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };

export namespace LearningItemType {
  const values = ["SUMMARY", "ACTIONABLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemType = LearningItemType.Impl;

export namespace LearningItemTypeActionable {
  const values = ["ACTIONABLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemTypeActionable = LearningItemTypeActionable.Impl;

export namespace LearningItemTypeSummary {
  const values = ["SUMMARY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemTypeSummary = LearningItemTypeSummary.Impl;

export type LgxpId = {
  contentType: "SKETCHNOTE";
  id: bigint;
};

export type LsvPaymentView = {
  accountNumber: string | null;
  acqAuthorizationCode: string | null;
  authorizationCode: string | null;
  bankIban: string | null;
  bankName: string | null;
  bankTown: string | null;
  clearingNumber: string | null;
  firstName: string;
  lastName: string;
  merchantId: string | null;
  refNumber: string | null;
  transactionId: number;
};

export type MIABAnalyticsFeedbackEventForm = {
  dataId: number;
  isPositive: boolean;
};

export type MIABOverviewPageProps = {
  channels: ChannelMetadata[];
};

export type MIABPageProps = {
  bookmarked: boolean;
  feedback: boolean | null;
  keyInsights: string[];
  questions: MIABQuestionMetadata[];
  similarMiabs: ContentMetadata[];
  summary: SummaryMetadata;
  userPortal: string | null;
};

export type MIABPopularSummariesResult = {
  miabs: ContentMetadata[];
  page: PageInfo;
};

export type MIABPrepareStepAiResponse = {
  point1: string;
  point2: string;
  point3: string;
  point4: string;
};

export type MIABQuestionMetadata = {
  question: string;
  sortOrder: number;
  title: string;
};

export type MIABQuestionsAiResponse = {
  question1: {
    question: string;
    title: string;
  };
  question2: {
    question: string;
    title: string;
  };
  question3: {
    question: string;
    title: string;
  };
  question4: {
    question: string;
    title: string;
  };
  question5: {
    question: string;
    title: string;
  };
};

export type MIABSummaryForm = {
  page: number;
  selectedChannel: bigint | null;
};

export type MilesPaymentView = {
  cardNumber: string | null;
  milesTransactionId: number | null;
  pin: string | null;
  transactionId: number;
};

export type MobileAppLoginPreferenceListView = {
  corporateId: number;
  corporationName: string;
};

export type MobileDevice = {
  corporateId: number;
  customerId: number;
  deviceId: string;
  deviceType: string;
  firstUsedAt: DateTime<true>;
  language: Language;
  lastPushAt: DateTime<true> | null;
  lastUsedAt: DateTime<true>;
  osType: string | null;
  pushFrequency: PushFrequency;
  pushToken: string | null;
  version: string;
};

export type MonetaryAmount = {
  amount: number;
  currencyCode: string;
  displayValue: string;
};

export type MyListPortletView = {
  items: MyListView[];
  metadata: PortletMetadata;
  page: number;
  pageSize: number;
  totalCount: bigint;
  viewAllUri: string | null;
};

export type MyListView = {
  coverUri: string;
  key: string;
  myListId: bigint;
  name: string;
};

export namespace NavigationDirection {
  const values = ["NEXT", "PREVIOUS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type NavigationDirection = NavigationDirection.Impl;

export type NewB2BReportForm = {
  active: boolean;
  activeUsersOnly: boolean;
  b2bCustomReport: boolean;
  ccRecipients: string;
  corporateId: number;
  customerId: number;
  dayOfWeek: number;
  downloadType: DownloadType;
  emailAdministrator: string | null;
  frequency: ReportFrequency;
  fromDate: { year: number; month: number; day: number } | null;
  ftpFolder: string;
  ftpHost: string;
  ftpPassphrase: string;
  ftpPassword: string;
  ftpPort: number;
  ftpRemoteFileName: string;
  ftpUserName: string;
  prefixFileName: boolean;
  privateKey: string | null;
  privateKeyContent: string;
  privateKeyEnabled: boolean;
  reportId: number;
  reportName: string;
  reportType: ReportType;
  specialReport: boolean;
  targetExcel: boolean;
  toDate: { year: number; month: number; day: number } | null;
  transferProtocol: TransferProtocol;
  zipFile: boolean;
};

export type OAuth2Token = {
  accessTokenExpiredAt: DateTime<true> | null;
  accessTokenValue: string | null;
  corporateId: number;
  isExpired: boolean;
  refreshTokenExpiredAt: DateTime<true> | null;
  refreshTokenValue: string | null;
  tokenType: string | null;
};

export namespace Oauth2Flow {
  const values = ["BASIC", "OAUTH2"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Oauth2Flow = Oauth2Flow.Impl;

export type PageInfo = {
  page: number;
  pageSize: number;
  totalCount: number;
};

export type PageRequest = {
  page: number;
  psize: number;
  sortBy: string | null;
  sortDesc: boolean;
};

export type PageViewMeta = {
  ci: bigint | null;
  ct: ContentItemType;
  orr: string;
  p: string;
  r: string | null;
};

export type PagedActionableMetadata = {
  items: ActionableMetadata[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedAuthorAdminListView = {
  items: AuthorAdminListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorCompletionExecutionLogListView = {
  items: ConnectorCompletionExecutionLogListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorContentExecutionListView = {
  items: ConnectorContentExecutionListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorContentExecutionLogListView = {
  items: ConnectorContentExecutionLogListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedCustomPageEditListView = {
  items: CustomPageEditListView[];
  pageInfo: PageInfo;
};

export type PagedCustomPageListView = {
  items: CustomPageListView[];
  pageInfo: PageInfo;
};

export type PagedEmailDefaultCategoryAdminView = {
  items: EmailDefaultCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedEmailEducationCategoryAdminView = {
  items: EmailEducationCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedEmailFollowUpCategoryAdminView = {
  items: EmailFollowUpCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedEmailManualCategoryAdminView = {
  items: EmailManualCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedEmailOnboardingCategoryAdminView = {
  items: EmailOnboardingCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedEmailRenewalCategoryAdminView = {
  items: EmailRenewalCategoryAdminView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PayPalPaymentView = {
  acqAuthorizationCode: string | null;
  authorizationCode: string | null;
  merchantId: string | null;
  paypalBillingAgreementId: string | null;
  refNumber: string | null;
  transactionId: number;
};

export namespace PaymentType {
  const values = ["CC", "INVOICE", "PAYPAL", "LSV", "MILES", "ALIPAY", "IOS", "ANDROID"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PaymentType = PaymentType.Impl;

export type Person = {
  firstName: string;
  gender: Gender;
  lastName: string | null;
  middleName: string | null;
};

export namespace PortletLayout {
  const values = ["GRID", "CAROUSEL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PortletLayout = PortletLayout.Impl;

export type PortletMetadata = {
  custom: boolean;
  defaultFilterAllLanguages: boolean;
  embedSortingFiltering: boolean;
  hideTitle: boolean;
  itemCount: number;
  itemLayout: ItemLayout;
  name: string;
  portletId: number;
  portletLayout: PortletLayout;
  subType: string | null;
  subtitle: string;
  title: string;
  type: PortletType;
};

export namespace PortletType {
  const values = [
    "IMAGETEXT",
    "SUMMARYLIST",
    "TRENDINGCHANNEL",
    "FEATUREDCHANNEL",
    "FOLLOWINGCHANNEL",
    "MYLIST",
    "PRIMARYTOPICCHANNEL",
    "CHANNELLIST",
    "ANNOTATIONLIST",
    "LGXPLIST",
    "SKETCHNOTESLGXP",
    "ACTIONABLELIST",
    "ACTIONABLELISTCUSTOM",
    "MYACTIONABLES",
    "FREESUMMARY",
    "SUMMARYLISTCUSTOM",
    "CUSTOMPAGELIST",
    "PROMOBOXCONFIG",
    "MYMIABS",
  ] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PortletType = PortletType.Impl;

export type ProductAdminView = {
  code: string;
  productId: bigint;
};

export type PromoBoxConfigEditView = {
  customBoxes: PromoBoxEditView[];
  defaultBoxes: PromoBoxEditView[];
  defaultBoxesHidden: number[];
  showDefaultBoxes: boolean;
};

export type PromoBoxConfigForm = {
  items: PromoBoxConfigItemForm[];
  itemsHidden: number[];
  showDefaults: boolean;
};

export type PromoBoxConfigItemForm = {
  endsAt: { year: number; month: number; day: number } | null;
  promoBoxId: number;
  startsAt: { year: number; month: number; day: number } | null;
};

export type PromoBoxConfigPreview = {
  boxes: PromoBoxView[];
};

export type PromoBoxConfigPreviewFilters = {
  language: Language;
  loggedIn: string;
  platform: string;
};

export type PromoBoxEditProps = {
  corporateId: number;
  languages: Language[];
  promoBoxId: number;
};

export type PromoBoxEditView = {
  backgroundColor: string | null;
  brightTextColor: boolean;
  corporateId: number;
  designApproved: boolean;
  endsAt: { year: number; month: number; day: number } | null;
  excludedLanguages: Language[];
  imageUri: string;
  localizedDescription: { additionalProperties: Record<string, string> };
  localizedImageUris: { additionalProperties: Record<string, string> };
  localizedTitle: { additionalProperties: Record<string, string> };
  localizedWebLinks: { additionalProperties: Record<string, string> };
  loggedIn: boolean;
  loggedOut: boolean;
  mobile: boolean;
  name: string;
  promoBoxId: number;
  requiredFeatures: string[];
  startsAt: { year: number; month: number; day: number } | null;
  tag: PromoBoxTag;
  web: boolean;
  webLink: string;
};

export type PromoBoxLibraryView = {
  customBoxes: PromoBoxEditView[];
  defaultBoxes: PromoBoxEditView[];
};

export type PromoBoxSaveRequest = {
  excludedLanguages: Language[];
  imageUri: string | null;
  localizedDescription: { additionalProperties: Record<string, string> };
  localizedImageUris: { additionalProperties: Record<string, string> };
  localizedTitle: { additionalProperties: Record<string, string> };
  localizedWebLinks: { additionalProperties: Record<string, string> };
  loggedIn: boolean;
  loggedOut: boolean;
  mobile: boolean;
  name: string;
  requiredFeatures: string[];
  tag: PromoBoxTag;
  web: boolean;
  webLink: string;
};

export namespace PromoBoxTag {
  const values = [
    "UNDEFINED",
    "EXPERT_WEBINAR",
    "PRO_TALK",
    "FEATURED",
    "FEATURED_SUMMARY",
    "FEATURED_ACTIONABLE",
    "FEATURED_TOPIC",
    "EMERGING_TREND",
    "INTERNATIONAL_DAY",
    "LEARNING_AT_WORK_WEEK",
    "Q_A",
    "VALUES_AND_CAPABILITIES",
    "RECOMMENDED_READS",
    "INTERNAL_THOUGHT_LEADER",
    "READING_CALENDAR",
    "NEW",
    "SPOTLIGHT",
    "PROMOTION",
    "DEAL",
    "NEWS",
    "CAMPAIGN",
    "BOOK_AWARD",
  ] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PromoBoxTag = PromoBoxTag.Impl;

export type PromoBoxView = {
  backgroundColor: string | null;
  brightTextColor: boolean;
  description: string | null;
  designApproved: boolean;
  imageUri: string;
  promoBoxId: number;
  tag: PromoBoxTag;
  title: string | null;
  webLink: string;
};

export namespace PushFrequency {
  const values = ["NONE", "DAILY", "ONCE_A_WEEK", "TWICE_A_MONTH", "ONCE_A_MONTH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PushFrequency = PushFrequency.Impl;

export type ReferencedItemEdit = {
  refItem: ReferencedLearningItem;
  tags: Tag[];
};

export type ReferencedItemSaveRequest = {
  items: ReferencedItemEdit[];
};

export type ReferencedLearningItem = { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint };

export type ReferencedLearningItemList = {
  items: ReferencedLearningItem[];
};

export type RenewSubscriptionForm = {
  ccNumber: string | null;
  ccType: string | null;
  cvv: string | null;
  existing: boolean;
  expMonth: string | null;
  expYear: string | null;
  lsvAccountNumber: string | null;
  lsvBankName: string | null;
  lsvBankTown: string | null;
  lsvClearingNumber: string | null;
  lsvFirstName: string | null;
  lsvIban: string | null;
  lsvLastName: string | null;
  manualPrice: number | null;
  paymentType: string | null;
  productId: number | null;
  promotionCode: string | null;
  street: string | null;
  subscriptionId: number;
  zip: string | null;
};

export namespace ReportFrequency {
  const values = ["ONE_TIME", "DAILY", "WEEKLY", "MONTHLY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ReportFrequency = ReportFrequency.Impl;

export type ReportListView = {
  active: boolean;
  corporateId: number;
  customerFullName: string;
  frequency: ReportFrequency;
  name: string;
  prefixFileName: boolean;
  reportId: number;
  reportType: ReportType;
  targetExcel: boolean;
  timeFrameFrom: { year: number; month: number; day: number };
  timeFrameTo: { year: number; month: number; day: number };
  zipFile: boolean;
};

export type ReportSaveRequest = {
  active: boolean;
  activeUsersOnly: boolean;
  ccRecipients: string;
  corporateId: number;
  customerId: number;
  dayOfWeek: number;
  downloadType: DownloadType;
  frequency: ReportFrequency;
  fromDate: { year: number; month: number; day: number } | null;
  ftpFolder: string;
  ftpHost: string;
  ftpPassphrase: string;
  ftpPassword: string;
  ftpPort: number;
  ftpRemoteFileName: string;
  ftpUsername: string;
  prefixFileName: boolean;
  privateKey: string | null;
  reportId: number;
  reportName: string;
  reportType: ReportType;
  specialReport: boolean;
  targetExcel: boolean;
  toDate: { year: number; month: number; day: number } | null;
  transferProtocol: TransferProtocol;
  zipFile: boolean;
};

export namespace ReportType {
  const values = ["USERS", "ACTIVITY", "EXTENDED_USERS_EXPORT", "ACTIONABLES_ACTIVITY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ReportType = ReportType.Impl;

export type ResendConfirmationEmailForm = {
  customerName: string | null;
  subscriptionId: number;
};

export type Result = {
  completion: boolean;
  duration: string;
};

export namespace RightsProbability {
  const values = ["UNPREDICTABLE", "LIKELY", "VERY_LIKELY", "UNLIKELY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RightsProbability = RightsProbability.Impl;

export namespace RightsState {
  const values = ["RIGHTS_NOT_REQUESTED", "PENDING", "PENDING_PUBLISHER_APPROVAL", "OK", "NO_RIGHTS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RightsState = RightsState.Impl;

export namespace RoleResource {
  const values = [
    "ROLE_ADMIN",
    "PPS_EDITORIAL",
    "PPS_RIGHTS",
    "PPS_BOOKMARKETING",
    "PPS_TOPTENLIST",
    "PPS_WRITERREPORT",
    "PPS_STANDARDREPORT",
    "PPS_ALLSUMMARIES_LIST",
    "PPS_PUBLISHERADMIN",
    "PPS_INTERNALSEARCH",
    "ASP_ACCOUNT_ADMIN",
    "PUBLISHER_STATS_ALL",
    "ASP_STATISTICS",
    "ASP_USER_ADMIN",
    "PPS_RIGHTSADMINLIST",
    "ASP_STATISTICS_INTERNAL",
    "ADMIN_TOOL",
    "CUSTOMER_ADMIN",
    "EMAILTOOL",
    "TRANSACTION_ADMIN",
    "AFFILIATE_CLV_STATISTICS",
    "MARKETING_FILE_UPLOAD",
    "CURRENCY_EXCHANGE_RATE",
    "PPS_EDITORREPORT",
    "ADMIN_RELOAD",
    "EMAILTOOL_SNIPPETS",
    "ASP_MASSIMPORT_TOOL",
    "ASP_USER_ADMIN_LASTACCESS",
    "ASP_CENTRAL_ADMIN",
    "TXT_BUNDLE_EDIT",
    "TXT_TEXT_SINGLEEDIT",
    "TXT_TEXT_MULTIEDIT",
    "TXT_TOBEREVIEWEDLIST",
    "TXT_TOBETRANSLATEDLIST",
    "TXT_INLINE_EDIT",
    "TXT_LATEST_CHANGES",
    "TXT_SEARCH",
    "TXT_MAJOR_CHANGE",
    "TXT_ONLINE_STATE",
    "CREDIT_CARD_SETTLEMENT",
    "TXT_BUNDLE_VIEW",
    "EMAIL_LIST",
    "SOCIAL_COMMENT_FLAG",
    "CUSTOMER_WRITER",
    "RECOMMENDER_EXPORT",
    "KREC_EDIT",
    "WEB_RIGHTS_REQUEST",
    "SCORM_PACKAGE",
    "PPS_VIEW",
    "AFFILIATE_ADMIN",
    "SUMMARY_QR_CODE",
    "MARC_XML",
    "REGENERATE_SUMMARY_FILES",
    "HR_ADMIN_TOOL",
    "SUMMARY_STATISTICS",
    "CF_SUBSCRIPTIONS",
    "ASP_SSO_DOMAIN",
    "CUSTOMER_ADMIN_SUBSCRIPTION",
    "CUSTOMER_ADMIN_FINANCE",
    "OAUTH2_CLIENT_ADMIN",
    "ASP_USER_GROUP_ADMIN",
    "TOP_DOWNLOADS_TOOL",
    "WRITER_PORTAL",
    "ASP_LOGO_UPLOAD",
    "PORTLET_ADMIN",
    "PORTLET_SUPER_ADMIN",
    "FREEABSTRACT_ADMIN_TOOL",
    "PROMOBOXES_ADMIN",
    "CORPORATE_CONTACT",
    "CORPORATE_COMMENTS",
    "PPS_NEW_CONTENT_INPUT",
    "PPS_NEW_CONTENT_INPUT_ADMIN",
    "ABSENCES_ADMIN",
    "CHANNEL_ADMIN",
    "PROMOBOXES",
    "SSO_DOMAINS",
    "PPS_PORTAL",
    "PPS_PORTAL_ADMIN",
    "CHANNEL_LIST",
    "B2B_STATISTICS",
    "LIBRARY_CONNECTOR",
    "CHANNEL_ADMIN_GLOBAL",
    "PPS_FREELANCER_CONTACT_TOOL",
    "PPS_API_PRODUCTION_POINTS",
    "PPS_API_PRODUCTION_POINTS_ADMIN",
    "PPS_API_WRITING_OFFER",
    "PPS_API_EDITING_OFFER",
    "PPS_FLOW",
    "PPS_FLOW_ADMIN",
    "PPS_API_JOB",
    "PPS_API_INVOICE",
    "SCIM_TOKEN_VIEWER",
    "PPS_API_OFFER",
    "PRODUCTION_REPORT",
    "PPS_RIGHTS_FLOW",
    "PPS_AUTHOR_TOOL",
    "GA_KNOWLEDGE_BASE",
    "GA_SERVICE_DESK",
    "PPS_KNOWLEDGE_BASE",
    "EDITORIAL_KNOWLEDGE_BASE",
    "LGXP_ADMIN",
    "PPS_RIGHTS_TEAM_REPORTS",
    "LGXP_GLOBAL_ADMIN",
    "PPS_EDITORIAL_TEAM_REPORTS",
    "PPS_API_SUMMARY_CREATION",
    "CUSTOMER_STORY_ADMIN",
    "PROMOTION_CODE_TOOL",
    "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT",
    "ASP_LIST",
    "PPS_USER_API",
    "ASP_SENSITIVE_CONTENT",
    "CHANNEL_ADMIN_SEARCH",
    "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT",
    "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT",
    "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT",
    "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT",
    "ACTIONABLE_ADMIN",
    "PPS_COMPONENT_DEMO",
    "ASP_MASSIMPORT_TOOL_EXTENDED",
    "ASP_UNIDENTIFIED_USER_ADMIN",
    "PPS_PAYMENT_TOOL",
    "HR_ADMIN_FULL_ACCESS",
    "PPS_ORIGINAL_SUMMARY_FILE_ACCESS",
    "CUSTOMER_REPORTS",
    "PPS_ARCHIVING_SUMMARY",
    "LGXP_ADMIN_SEARCH",
    "PPS_USER_BETA_TEST",
    "ACTIONABLE_ADMIN_SEARCH",
    "CUSTOM_PAGE_ADMIN",
    "PPS_EXPENSE_TOOL",
    "VERIFY_INPUT_TOOL",
    "VERIFY_PUBLISH",
    "VERIFY_UPLOAD",
    "VERIFY_DASHBOARD",
    "CSM_ADVANCED_SEARCH",
    "VERIFY_VERIFICATION",
    "VERIFY_EDITING",
    "SUMMARIES_BULK_LOAD",
    "ACTIONABLES_BULK_LOAD",
  ] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RoleResource = RoleResource.Impl;

export type RoleResourceConstraint = {
  corporateIds: number[];
  customerId: number;
  languages: Language[];
  resource: RoleResource;
};

export type SearchFiltersForm = {
  actionableLanguageFormFilter: Language[];
  channelPage: number;
  query: string;
  summariesPage: number;
  summarySearchFilters: SummarySearchFilters;
};

export type ShareEvent = {
  contentItemType: ContentItemType;
  id: bigint;
  shareMedium: "COPY_LINK";
};

export type SkillTaxonomy = {
  skills: {
    sub_skills: {
      sub_skills: {
        title: string;
        title_DE: string | null;
        title_EN: string | null;
        title_ES: string | null;
      }[];
      title: string;
      title_DE: string | null;
      title_EN: string | null;
      title_ES: string | null;
    }[];
    title: string;
    title_DE: string | null;
    title_EN: string | null;
    title_ES: string | null;
  }[];
};

export namespace StudentIdVerificationProvider {
  const values = ["UNIDAYS", "UNIDAYS_PERK", "SHEERID", "MANUAL", "UNKNOWN"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type StudentIdVerificationProvider = StudentIdVerificationProvider.Impl;

export namespace StudentVerificationStatus {
  const values = ["VERIFIED", "EXPIRED", "FAILED", "IN_PROGRESS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type StudentVerificationStatus = StudentVerificationStatus.Impl;

export type StudentVerificationView = {
  countryOfStudy: string;
  createdAt: DateTime<true>;
  fieldOfStudy: string | null;
  proof: string | null;
  provider: StudentIdVerificationProvider;
  status: StudentVerificationStatus;
  subscriptionId: number | null;
  verifiedAt: DateTime<true> | null;
};

export type SubscriptionAdminForm = {
  extraMonths: number | null;
  isPaid: boolean;
  productId: number;
  reference: string | null;
  showCommentModal: boolean;
  status: SubscriptionStatus;
  studentVerificationCountryOfStudy: string | null;
  studentVerificationFieldOfStudy: string | null;
  studentVerificationProof: string | null;
  studentVerificationProvider: string | null;
  studentVerificationStatus: string | null;
  studentVerificationVerifiedAt: DateTime<true> | null;
  subscriptionEndDate: { year: number; month: number; day: number };
  subscriptionId: number;
  subscriptionStartDate: { year: number; month: number; day: number };
  transactions: SubscriptionTransaction[];
};

export type SubscriptionAdminView = {
  affiliate: string | null;
  cancelLinkForCustomerUri: string;
  comment: string | null;
  customerId: number;
  emailAddressForCancelConfirmation: string;
  extraMonths: number | null;
  giftCertificateUri: string | null;
  giftCodesAvailable: GiftCodeStatus;
  invoicesAndReminders: Invoice[] | null;
  isGiftSubscription: boolean;
  isPaid: boolean;
  motivationToBuy: string | null;
  productId: number;
  productName: string;
  promotionCode: string | null;
  reference: string;
  referredBy: string | null;
  renewSource: SubscriptionRenewSource;
  status: SubscriptionStatus;
  studentVerification: StudentVerificationView | null;
  subscriptionEndDate: { year: number; month: number; day: number };
  subscriptionId: number;
  subscriptionRenewInfo: SubscriptionRenewInfoView | null;
  subscriptionStartDate: { year: number; month: number; day: number };
  transactions: SubscriptionTransaction[];
};

export type SubscriptionCreditForm = {
  amountToCredit: number;
  balance: number;
  comment: string | null;
  sendEmail: boolean;
  stopSubscription: boolean;
  subscriptionId: number;
  transactionTypeId: number;
};

export type SubscriptionCreditView = {
  amountToCredit: number;
  balance: number;
  currencyCode: string | null;
  customerId: number;
  emailSubject: string | null;
  emailText: string | null;
  isEmailHtml: boolean | null;
  isGiftSubscription: boolean;
  isSubscriptionStopped: boolean;
  subscriptionId: number;
  transaction: SubscriptionTransaction;
};

export type SubscriptionPayment = {
  balance: MonetaryAmount;
  isMobilePayment: boolean;
  paymentType: string | null;
};

export type SubscriptionRenewInfoView = {
  ccNumber: string | null;
  ccType: CreditCardType | null;
  cvv: string | null;
  expMonth: string | null;
  expYear: string | null;
  lsvAccountNumber: string | null;
  lsvBankIban: string | null;
  lsvBankName: string | null;
  lsvBankTown: string | null;
  lsvClearingNumber: string | null;
  lsvFirstName: string | null;
  lsvLastName: string | null;
  manualPrice: number | null;
  paymentType: string | null;
  productId: number | null;
  promotionCode: string | null;
  street: string | null;
  subscriptionId: number;
  zip: string | null;
};

export namespace SubscriptionRenewSource {
  const values = ["NEW", "AUTOMATIC", "MANUAL", "UPGRADE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SubscriptionRenewSource = SubscriptionRenewSource.Impl;

export namespace SubscriptionStatus {
  const values = ["ACTIVE", "STOP", "CANCEL", "RENEWED", "RENEWALFAILED"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SubscriptionStatus = SubscriptionStatus.Impl;

export type SubscriptionTransaction = {
  amount: DisplayMonetaryAmount;
  comment: string | null;
  customerId: number;
  generatedAt: { year: number; month: number; day: number };
  paymentInfoAlipay: AlipayPaymentView | null;
  paymentInfoAndroid: AndroidPaymentView | null;
  paymentInfoCC: CreditCardPaymentView | null;
  paymentInfoIOS: IosPaymentView | null;
  paymentInfoInvoice: InvoicePaymentView | null;
  paymentInfoLSV: LsvPaymentView | null;
  paymentInfoMiles: MilesPaymentView | null;
  paymentInfoPaypal: PayPalPaymentView | null;
  paymentReceiptUri: string;
  paymentType: PaymentType;
  quantity: number;
  subscriptionId: number;
  transactionId: number;
  transactionType: "DEBIT" | "CREDIT";
};

export type SummaryBulkLoadForm = {
  corporateId: number | null;
  dataIds: number[];
  includeInactive: boolean;
};

export type SummaryCardDemoProps = {
  summaryList: SummaryMetadata[];
};

export type SummaryContent = {
  aboutAuthorHtml: string | null;
  aboutTextHtml: string | null;
  abstractTextHtml: string | null;
  bookId: number;
  dataId: number;
  historicalBackgroundHtml: string | null;
  introHtml: string | null;
  longReviewHtml: string | null;
  outputFormat: SummaryOutputFormat;
  reviewLeadHtml: string | null;
  reviewTitle: string | null;
  sourceIsbn: string | null;
  sourcePages: number;
  sourceUrl: string | null;
  takeaways: string[];
};

export type SummaryCoverInformation = {
  imageUri: string;
  summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
  title: string | null;
};

export namespace SummaryDocumentType {
  const values = ["ABSTRACT", "REVIEW"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SummaryDocumentType = SummaryDocumentType.Impl;

export type SummaryId = {
  contentType: "SUMMARY";
  id: bigint;
};

export type SummaryIxStatusView = {
  bookmarkedAt: DateTime<true> | null;
  consumedAt: DateTime<true> | null;
  customerId: number;
  dataId: number;
  likedAt: DateTime<true> | null;
  readingProgress: number;
};

export type SummaryMetadata = {
  activatedAt: DateTime<true> | null;
  activationTime: DateTime<true> | null;
  active: boolean;
  audioActivatedAt: DateTime<true> | null;
  audioActive: boolean;
  audioDuration: number;
  authorInfo: string | null;
  authors: {
    authorId: number;
    firstName: string | null;
    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
    lastName: string | null;
    middleName: string | null;
  }[];
  backgroundColor: string | null;
  bestseller: boolean;
  businessClassic: boolean;
  canonicalName: string;
  contentItemId: bigint;
  contentItemType: ContentItemType;
  countriesExcluded: Country[];
  countriesIncluded: Country[];
  coverModifiedAt: DateTime<true> | null;
  coverUri: string;
  dataId: number;
  descriptionHtml: string | null;
  documentType: SummaryDocumentType;
  duration: number;
  imageBorder: boolean;
  language: Language;
  modifiedAt: DateTime<true> | null;
  originalTitle: string | null;
  previewAvailable: boolean;
  publicationDate: string | null;
  publisherInfo: string | null;
  publisherName: string | null;
  rating: number | null;
  sourceType: SummarySourceType;
  subtitle: string | null;
  summaryPages: number;
  summaryWords: number;
  tags: Tag[];
  teaser: string | null;
  title: string | null;
};

export namespace SummaryOutputFormat {
  const values = ["TWO_PAGES", "FIVE_PAGES", "EIGHT_PAGES"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SummaryOutputFormat = SummaryOutputFormat.Impl;

export type SummarySearchFilters = {
  audioFormFilter: boolean;
  languageFormFilter: string[];
  miabFormFilter: boolean;
  publicationDateFormFilter: number[];
  qualityFormFilter: string[];
  ratingFormFilter: number[];
  sourceFormFilter: string[];
};

export namespace SummarySourceType {
  const values = ["BOOK", "VIDEO", "ARTICLE", "CHAPTER", "REPORT", "PODCAST"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SummarySourceType = SummarySourceType.Impl;

export type Tag =
  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };

export type TagAdminFilter = {
  query: string;
  tagKinds: TagKind[];
};

export type TagImport = {
  data: {
    dataId: number;
    primary_skill: string;
    secondary_skills: string[];
  }[];
};

export type TagKind =
  | { discriminator: "classification"; id: bigint; kind: KindClassification }
  | { discriminator: "channel"; id: bigint; kind: KindChannel }
  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
  | { discriminator: "skill"; id: bigint; kind: KindSkill }
  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
  | { discriminator: "dynamicString"; id: bigint; kind: string }
  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
  | { discriminator: "dynamicNumber"; id: bigint; kind: string };

export type TagKindChannel = {
  discriminator: KindChannel;
  id: bigint;
  kind: KindChannel;
};

export type TagKindClassification = {
  discriminator: KindClassification;
  id: bigint;
  kind: KindClassification;
};

export type TagKindDynamicBoolean = {
  discriminator: KindDynamicBoolean;
  id: bigint;
  kind: string;
};

export type TagKindDynamicNumber = {
  discriminator: KindDynamicNumber;
  id: bigint;
  kind: string;
};

export type TagKindDynamicString = {
  discriminator: KindDynamicString;
  id: bigint;
  kind: string;
};

export type TagKindDynamicVoid = {
  discriminator: KindDynamicVoid;
  id: bigint;
  kind: string;
};

export type TagKindKeyQuality = {
  discriminator: KindKeyQuality;
  id: bigint;
  kind: KindKeyQuality;
};

export type TagKindPrimarySkill = {
  discriminator: KindPrimarySkill;
  id: bigint;
  kind: KindPrimarySkill;
};

export type TagKindSkill = {
  discriminator: KindSkill;
  id: bigint;
  kind: KindSkill;
};

export namespace TagSourceType {
  const values = ["USER", "AI"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type TagSourceType = TagSourceType.Impl;

export type TagValidationResponse = { status: "success" } | { status: "error"; message: string };

export type TaggedItem = {
  item: ReferencedLearningItem;
  tag: Tag;
};

export type TeamSubscription = {
  teamPortalCorporateId: number;
  teamPortalUrl: string;
  teamPortalUsername: string;
};

export type ToastState = {
  message: string;
  title: string | null;
  uuid: string;
  variant: ToastVariant;
};

export namespace ToastVariant {
  const values = ["primary", "success", "warning", "danger"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ToastVariant = ToastVariant.Impl;

export namespace TransferProtocol {
  const values = ["FTP", "SFTP", "FTPS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type TransferProtocol = TransferProtocol.Impl;

export type Translations = {
  keys: { additionalProperties: Record<string, string> };
};

export type UserContext = {
  loggedIn: boolean;
  u: string | null;
};

export type ValidationErrorReasons = {
  fields: { additionalProperties: Record<string, string[]> };
  general: string[];
};

export type Verb = {
  display: { additionalProperties: Record<string, string> };
  id: string;
};

export type VerifaiCreatorPageProps = {
  hashId: string;
};

export type VerifaiNavLink = {
  ico: string | null;
  name: string;
  url: string;
};

export type VerifaiNavigationProps = {
  navLinks: VerifaiNavLink[];
};

export type VueDemoProps = {
  language: Language;
  subtitle: string;
};

export type WarmupV2EditForm = {
  countryCode: string;
  email: string;
  firstName: string;
  frequency: PushFrequency;
  language: Language;
  lastName: string;
  password: string;
  termsAndConditions: boolean;
};

export type WarmupV2RelayType = { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string };

export type WarmupV2SsoRequest = {
  corporateId: number;
  function: string | null;
  ssoUid: string;
  title: number | null;
  town: string | null;
};

export type WarmupV2Submission = {
  evrUuid: string;
  form: WarmupV2EditForm;
  ssoRequest: WarmupV2SsoRequest;
};

export type WarmupV2ValidatedForm = {
  countryCode: string;
  email: string;
  firstName: string;
  frequency: PushFrequency;
  language: Language;
  lastName: string;
  password: string;
  termsAndConditions: boolean;
};

export type WarmupV2ValidationResult = { kind: "SUCCESS"; validatedForm: WarmupV2ValidatedForm } | { kind: "ERROR"; reasons: ValidationErrorReasons };

export type WarmupV2WidgetProps = {
  corporationName: string;
  countryCode: string;
  email: string;
  evrUuid: string;
  firstName: string;
  language: Language;
  lastName: string;
  relayType: WarmupV2RelayType;
  ssoRequest: WarmupV2SsoRequest;
};

export type WishlistManagementData = {
  editorOptions: DropdownEntry[];
  list: WishlistManagementItemList;
};

export type WishlistManagementEditorUpdate = {
  managingEditorCustomerId: number | null;
};

export type WishlistManagementItem = {
  coverUri: string | null;
  dataId: number | null;
  description: string;
  detailInfo: string;
  isbn: string | null;
  krecId: number;
  language: Language;
  managingEditorCustomerId: number | null;
  rightsGranted: boolean;
  suggestedAt: DateTime<true>;
  tinyUrl: string | null;
  title: string;
};

export type WishlistManagementItemList = {
  items: WishlistManagementItem[];
};

export type WishlistManagementLanguageUpdate = {
  language: Language;
};

export namespace XApiActorId {
  const values = ["MBOX", "ACCOUNT", "OPENID"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type XApiActorId = XApiActorId.Impl;

export type XApiStatement = {
  actor: Actor;
  object: Activity;
  result: Result;
  timestamp: string;
  verb: Verb;
};
