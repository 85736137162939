import { Language } from "@utils/type/type";
import { createI18n } from "vue-i18n";
import { assert } from "@utils/assertion";

import { setupGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { getResourceConstraints } from "@generated/api/roleResourceConstraintControllerApi";
import { getFrontendTranslations } from "@generated/api/txtRestControllerApi";
import { dictToList } from "@utils/dictUtils";

// Number formats needed so that we can format currencies.
const numberFormats = {
  en: {
    currency: {
      style: "currency",
    },
  },
  de: {
    currency: {
      style: "currency",
    },
  },
  es: {
    currency: {
      style: "currency",
    },
  },
  ru: {
    currency: {
      style: "currency",
    },
  },
  zh: {
    currency: {
      style: "currency",
    },
  },
  pt: {
    currency: {
      style: "currency",
    },
  },
  fr: {
    currency: {
      style: "currency",
    },
  },
} as const;

export const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  numberFormats: numberFormats,
});

async function setupTmsMessages(lang: Language): Promise<void> {
  async function loadTranslation(lang: Language): Promise<Map<string, string>> {
    const response = await getFrontendTranslations("DESIGN", lang);
    i18n.global.setLocaleMessage(lang, response.keys.additionalProperties);
    return new Map(dictToList(response.keys.additionalProperties));
  }

  await setupGaContext(
    { currentLanguage: lang, flags: { beta: false } },
    {
      en: () => loadTranslation("en"),
      de: () => loadTranslation("de"),
      es: () => loadTranslation("es"),
      ru: () => loadTranslation("ru"),
      zh: () => loadTranslation("zh"),
      pt: () => loadTranslation("pt"),
      fr: () => loadTranslation("fr"),
      roleResourceConstraints: getResourceConstraints,
    },
  );
}

export type TranslateFunction = (key: string, argumentList?: string[]) => string;

/**
 * @deprecated use `useGaContext()` instead.
 */
export function useI18n(): { t: (key: string, argumentList?: string[]) => string };
export function useI18n(lang: Language): Promise<{ t: (key: string, argumentList?: string[]) => string }>;

export function useI18n(lang?: Language):
  | {
      t: TranslateFunction;
    }
  | Promise<{
      t: TranslateFunction;
    }> {
  if (lang !== undefined) {
    assert(Language.getValues().includes(lang), `'${lang}' is not a valid or supported ISO code (must be lower case).`);
    return setupTmsMessages(lang).then(() => {
      const { t } = i18n.global;
      return {
        t: (key: string, argumentList?: string[]) => t(key, argumentList ?? [], { locale: lang }),
      };
    });
  }
  const { t } = i18n.global;
  return {
    t: (key: string, argumentList?: string[]) => t(key, argumentList ?? []),
  };
}

export async function setI18nLanguage(lang: Language): Promise<void> {
  assert(Language.getValues().includes(lang), `'${lang}' is not a valid or supported ISO code (must be lower case).`);
  await setupTmsMessages(lang);
  i18n.global.locale.value = lang;
}
