import { internalData, syncDataMissingMessage } from "@utils/vue-migration/common/gaContext/gaContextInternals";
import { assert } from "@utils/assertion";
// eslint-disable-next-line no-restricted-imports
import type { GaContextFlags } from "@newgenerated/shared/schema";

export function getFlags(): GaContextFlags {
  const flags = internalData.sync.flags;
  assert(flags.state === "AVAILABLE", syncDataMissingMessage("flags"));
  return structuredClone(flags.value);
}
